import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo, Text } from 'components';
import { AppState } from 'state/types';
import HowToSection from './components/HowToSection';
import ThankYouSection from './components/ThankYouSection';
import { OrderSummary } from '../shipping/components/OrderSummary';
import Header from 'components/Header';
import { usePageView, useQuizData } from 'utils/hooks';
import LCFooter from 'components/LCFooter';
import { mobile, tablet } from 'styles/breakpoints';
import Tracking from 'utils/tracking';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  background: #f9f9f9;
  min-height: calc(100vh - 72px);

  @media ${tablet} {
    padding: 1.3125rem 0 3.125rem;
    min-height: calc(100vh - 3.5rem);
  }
`;

const Title = styled(Text)`
  color: #2a4b42;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 130%;
  text-align: left;
  margin-bottom: 0.61225rem;
  @media ${tablet} {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Subtitle = styled(Text)`
  color: #2a4b42;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 130%;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const Star = styled(DynamicImage)`
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.822rem;
  @media ${tablet} {
    width: 2.25rem;
    height: 2.25rem;
  }
`;

const Success: FC<RouteComponentProps> = () => {
  const code = useSelector((state: AppState) => state.user.code);
  const data = useQuizData('success');
  const shippingData = useQuizData('shipping');
  const {
    selected_plans,
    upsell_products,
    quiz_answers,
    selected_plans_options,
  } = useSelector((state: AppState) => state.user);

  const hasOnlySubscription =
    selected_plans?.length === 1 && selected_plans[0]?.is_subscription;

  const boughtSub =
    selected_plans?.some(item => item.is_subscription) ||
    upsell_products?.some(item => item.is_subscription);

  usePageView();

  useEffect(() => {
    if (!code) {
      return;
    }

    Tracking.logEvent({
      event: 'email_left',
      client_code: code,
    });
  }, [code]);

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header
        logoVariant="center"
        color={'grayBg'}
        hasBorder={true}
        sticky={false}
      />
      <Container>
        <TitleContainer>
          <Star alt="star" src="success/star_check.png" />
          <Title>{data.title}</Title>
          <Subtitle>{data.subtitle}</Subtitle>
        </TitleContainer>
        <OrderSummary
          productMap={shippingData.productMap}
          delayMessage={shippingData.delayMessage}
          bundleInfo={data.bundleInfo}
          isSuccess
          gifts={data.gifts}
          upsellsKeys={data?.upsellsKeys}
          bundlePhoto={data?.bundlePhoto}
        />
      </Container>
      {/* <LCFooter disclaimerText={data?.disclaimerParagraphs || []} /> */}
    </>
  );
};

export default Success;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  max-width: 26.3rem;
  width: 100%;
  @media ${tablet} {
    max-width: unset;
    padding: 0 1rem 0.75rem;
  }
`;
